<template>
    <div>
        <component
            :is="components[implementationType]"
            v-if="implementationType && components[implementationType]"
            :url="url"
            :height="height"
            :description="description"
        />
    </div>
</template>
<script setup lang="ts">

import type {BaseEmbedProps, ComponentMap, EmbedType} from '~/components/BaseEmbed/BaseEmbed.types';

const props = defineProps<BaseEmbedProps>();

const components = {
    'buzzsprout': resolveComponent('BaseEmbedBuzzsprout'),
    'issuu': resolveComponent('BaseEmbedIssuu'),
    'flippingbook': resolveComponent('BaseEmbedFlippingbook'),
    'vimeo': resolveComponent('BaseEmbedVimeo'),
    'youtube': resolveComponent('BaseEmbedYoutube'),
    'iframe': resolveComponent('BaseEmbedIframe')
} as ComponentMap;

const implementationType = computed<EmbedType>(() => {
    if (!props?.url) {
        return null;
    }
    if (props.url?.includes('vimeo.com/')) {
        return 'vimeo';
    }
    if (props.url?.includes('issuu.com/')) {
        return 'issuu';
    }
    if (props.url?.includes('online.flippingbook.com/') ||
        props.url?.includes('publications.uroweb.org/')) {
        return 'flippingbook';
    }
    if (props.url?.includes('buzzsprout.com/')) {
        return 'buzzsprout';
    }
    if (props.url?.includes('https://youtube.be/')) {
        return 'youtube';
    }
    if (props.url?.includes('v=')) {
        return 'youtube';
    }
    return 'iframe';
});

</script>

<style lang="less" src="./BaseEmbed.less" />
